<template>
  <div class="b-page">
    <div class="b-page__content" >
      <h1 class="main-page">{{services[idItem].name}}</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <div class="b-page__content">
              <div class="service-detail">
              <div class="service-detail__head">
                <div class="row">
                  <div class="col col-md-5 service-detail__head-left">
                    <img class="service-detail__detailimg" :alt="services[idItem].name" :title="services[idItem].name" :src="services[idItem].img">
                  </div>
                  <div class="col col-md-5 service-detail__head-right">

                    <div class="service-prop">
                      <div class="service-prop__icon">
                        <svg class="icon-svg svg-wallet"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-wallet"></use></svg>
                      </div>
                      <div class="service-prop__block">
                        <div class="service-prop__name">Стоимость:</div>
                        <div class="service-prop__value"><span>{{ services[idItem].startPrice }}</span> руб.</div>
                      </div>
                    </div>


                    <div class="service-detail__order">
                      <router-link class="btn btn-primary "  title=" Заказать услугу" to="/singup"> Записаться</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import myStore from '@/store/Store'
export default {
  name: "Service",

  setup() {
    const route = useRoute()
    console.log(route.params.id)
    const idItem = ref(route.params.id)
    const {services} = myStore()

    return {
      services,
      idItem
    }
  }
}
</script>

<style scoped>

</style>